import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";

export default {
	EVENTS: {
		title: "Events",
		category: "ARTICLES_EVENTS",
		description: "It allows to use Events content type.",
		image: "/thumbnails/data-packs/Events/thumbnail@1x.png",
	},
	STAFF: {
		title: "Staff",
		category: "PEOPLE",
		description: "It allows to use Staff content type.",
		image: "/thumbnails/data-packs/Staff/thumbnail-2x.png",
	},
	NEWS: {
		title: "News",
		category: "NEWS",
		description: "It allows to use News content type.",
		image: "/thumbnails/data-packs/News/thumbnail-2x.png",
	},
	LANDINGS: {
		title: "Landings",
		category: "LANDINGS",
		description: "It allows to use Landings content type.",
		image: "/thumbnails/data-packs/Landings/thumbnail-2x.png",
	},
	TESTIMONIALS: {
		title: "Testimonials",
		category: "PEOPLE",
		description: "It allows to use Testimonials content type.",
		image: "/thumbnails/data-packs/Testimonials/thumbnail-2x.png",
	},
	COMPANIES: {
		title: "Companies",
		category: "COMPANIES",
		description: "It allows to use Companies content type.",
		image: "/thumbnails/data-packs/Companies/thumbnail-2x.png",
	},
	AWARDS: {
		title: "Awards",
		category: "AWARDS",
		description: "It allows to use Awards content type.",
		image: "/thumbnails/data-packs/Awards/thumbnail-2x.png",
	},
	STUDY: {
		title: "Study",
		category: "STUDY",
		description: "It allows to use Study content type.",
		image: "/thumbnails/data-packs/Study/thumbnail-2x.png",
	},
	STUDY_LANDING: {
		title: "Study landing",
		category: "STUDY_LANDING",
		description: "It allows to use Study Landing content type.",
		image: "/thumbnails/data-packs/Study/thumbnail-2x.png",
	},
	STUDY_LANDING_TYPE: {
		title: "Study type landing",
		category: "STUDY_LANDING_TYPE",
		description: "It allows to use Study Type Landing content type.",
		image: "/thumbnails/data-packs/Study/thumbnail-2x.png",
	},
	BLOG: {
		title: "Blog",
		category: "BLOG",
		description: "It allows to use Blog content type.",
		image: "/thumbnails/data-packs/Blog/thumbnail-2x.png",
	},
	JOB_OFFERS: {
		title: "Job Offers",
		category: "JOB_OFFERS",
		description: "It allows to use Alumni content type.",
		image: "/thumbnails/data-packs/JobOffers/thumbnail-2x.png",
	},
	MODALS: {
		title: "Modals",
		category: "MODALS",
		description: "It allows to use Modals content type.",
		image: "/thumbnails/data-packs/Modals/thumbnail-2x.png",
	},
	QUIZZ: {
		title: "Quizz",
		category: "QUIZZ",
		description: "It allows to use Quizz content type.",
		image: "/thumbnails/data-packs/Quizz/thumbnail-2x.png",
	},
	DISCOUNTS: {
		title: "Discounts",
		category: "DISCOUNTS",
		description: "It allows to use Discounts content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Discounts/discounts.png`,
	},
	ALUMNI: {
		title: "Alumni",
		category: "ALUMNI",
		description: "It allows to use Alumni content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Alumni/alumni.png`,
	},
	WORD: {
		title: "Word",
		category: "WORD",
		description: "It allows to use Word content type.",
		image: `${CLOUDINARY_BASE_UPLOAD_URL}/data-packs-thumbnails/Word/word.png`,
	},
};
